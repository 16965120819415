html,
body {
  height: 100%;
  background-color: rgb(41, 45, 62);
  /* background-color: #435a6b; */
}
body {
  /* color: #ecf0f1; */
  color: rgb(191, 199, 213);
  text-align: center;
}

/* Links */
b,
a,
a:focus,
a:hover {
  color: #09d3ac;
}

.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 50%;
    align-items: center;
    margin: auto;
    justify-content: center;
    margin-top: -10%;

}